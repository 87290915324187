'use strict';

import '../css/app.scss';
import EmailSpamProtection from "./email-spam-protection";

async function loadJQuery() {
    const {default: jQuery} = await import(
        /* webpackChunkName: "jquery" */
        'jquery/dist/jquery'
    );
    return jQuery;
}
async function loadBootstrap() {
    const {default: Bootstrap} = await import(
        /* webpackChunkName: "bootstrap" */
        'bootstrap/dist/js/bootstrap'
    );
    return Bootstrap;
}

loadJQuery().then((jQuery) => {
    /* fix for https://github.com/symfony/webpack-encore/pull/54 */
    global.$ = global.jQuery = jQuery;

    loadBootstrap().then(() => {
    });

    const emailSpamProtection = new EmailSpamProtection();
    global.linkTo_UnCryptMailto = (s, rotation) => emailSpamProtection.linkTo_UnCryptMailto(s, rotation);
});
