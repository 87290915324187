'use strict';

/**
 * EmailSpamProtectionUtility
 * See: src/Utility/EmailSpamProtectionUtility.php
 *
 * const emailSpamProtection = new EmailSpamProtection();
 * global.linkTo_UnCryptMailto = (s, rotation) => emailSpamProtection.linkTo_UnCryptMailto(s, rotation);
 */
export default class EmailSpamProtection {
    decryptCharcode(n, start, end, offset) {
        n = n + offset;
        if (offset > 0 && n > end) {
            n = start + (n - end - 1);
        } else if (offset < 0 && n < start) {
            n = end - (start - n - 1);
        }
        return String.fromCharCode(n);
    }

    decryptString(encrypt, offset) {
        let decrypt = '';
        for (let i = 0; i < encrypt.length; i++) {
            let n = encrypt.charCodeAt(i);
            if (n >= 0x2B && n <= 0x3A) {
                decrypt += this.decryptCharcode(n, 0x2B, 0x3A, offset); // 0-9 . , - + / :
            } else if (n >= 0x40 && n <= 0x5A) {
                decrypt += this.decryptCharcode(n, 0x40, 0x5A, offset); // A-Z @
            } else if (n >= 0x61 && n <= 0x7A) {
                decrypt += this.decryptCharcode(n, 0x61, 0x7A, offset); // a-z
            } else {
                decrypt += encrypt.charAt(i);
            }
        }
        return decrypt;
    }

    // Decrypt spam-protected emails
    linkTo_UnCryptMailto(s, rotation) {
        if (typeof rotation === 'undefined') {
            rotation = 0;
        }
        location.href = this.decryptString(s, rotation * -1);
    }
}
